/* eslint-disable camelcase */
import { NotionRenderer } from 'react-notion-x'
import 'react-notion-x/src/styles.css'
import { LayoutContainer } from '../../Components/Layout'
import helpCenterJson from './help_center.notion.json' // https://www.notion.so/wledplus/WLED-Help-Center-b78a0de359e74517a86b9af5f302e060

function WLEDPlusSupport() {
  return (
    <LayoutContainer title='Support'>
      <NotionRenderer
        darkMode={true}
        recordMap={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          block: helpCenterJson as unknown as any,
          collection: {},
          collection_view: {},
          notion_user: {},
          collection_query: {},
          signed_urls: {},
        }}
      />
    </LayoutContainer>
  )
}

export default WLEDPlusSupport
