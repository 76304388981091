/* eslint-disable camelcase */
import { NotionRenderer } from 'react-notion-x'
import 'react-notion-x/src/styles.css'
import { LayoutContainer } from '../../Components/Layout'
import privacyPolicyJson from './privacy_policy.notion.json'

function WLEDPlusPrivacy() {
  return (
    <LayoutContainer title='Privacy Policy'>
      <NotionRenderer
        darkMode={true}
        recordMap={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          block: privacyPolicyJson as unknown as any,
          collection: {},
          collection_view: {},
          notion_user: {},
          collection_query: {},
          signed_urls: {},
        }}
      />
    </LayoutContainer>
  )
}

export default WLEDPlusPrivacy
